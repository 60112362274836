.sv-footer.sv-action-bar {
  display: block;
  min-height: $base-line-height;
  padding: 2.5em 0 0.87em 0;
  margin-left: auto;

  .sv-action__content {
    display: block;
  }

  .sv-action:not(:last-child) {
    .sv-action__content {
      padding-right: 0;
    }
  }
}

.sv-btn--navigation {
  margin: 0 1em;
  float: right;
  background-color: $main-color;
}

.sv-footer__complete-btn,
.sv-footer__next-btn,
.sv-footer__preview-btn {
  float: right;
}

.sv-footer__prev-btn,
.sv-footer__edit-btn {
  float: left;
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .sv-footer__complete-btn {
    float: left;
  }

  .sv-footer__preview-btn {
    float: left;
  }

  .sv-footer__next-btn {
    float: left;
  }

  .sv-footer__prev-btn {
    float: right;
  }

  .sv-footer__edit-btn {
    float: right;
  }
}