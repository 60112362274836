.sv-question .sv-selectbase {
  margin-bottom: 4px;
}

.sv-selectbase__item {
  margin-bottom: 0.425em;
  vertical-align: top;
}

.sv-selectbase__item--inline {
  display: inline-block;
  padding-right: 5%;
}

.sv-selectbase__column {
  min-width: 140px;
  vertical-align: top;
}

.sv-selectbase__label {
  position: relative;
  display: block;
  box-sizing: border-box;
  cursor: inherit;
  margin-left: 41px;
  min-height: 30px;
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .sv-selectbase__label {
    margin-right: 41px;
    margin-left: 0;
  }
}

.sv-selectbase__decorator.sv-item__decorator {
  position: absolute;
  left: -41px;
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .sv-selectbase__decorator.sv-item__decorator {
    left: initial;
    right: -41px;
  }
}

.sv-selectbase__clear-btn {
  margin-top: 0.9em;
  background-color: $clean-button-color;
}

.sv-selectbase .sv-selectbase__item.sv-q-col-1 {
  padding-right: 0;
}