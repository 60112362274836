﻿@import "./modern/constants.scss";
@import "./modern/blocks/sv-dragdrop.scss";
@import "./modern/blocks/sv-window.scss";
@import "./common-styles/sv-popup.scss";
@import "./modern/blocks/sv-container.scss";
@import "./modern/blocks/sv-body.scss";
@import "./modern/blocks/sv-page.scss";
@import "./modern/blocks/sv-panel.scss";
@import "./modern/blocks/sv-paneldynamic.scss";
@import "./modern/blocks/sv-row.scss";
@import "./modern/blocks/sv-question.scss";
@import "./modern/blocks/sv-progress.scss";
@import "./modern/blocks/sv-title.scss";
@import "./modern/blocks/sv-description.scss";
@import "./modern/blocks/sv-selectbase.scss";
@import "./modern/blocks/sv-multipletext.scss";
@import "./modern/blocks/sv-matrix.scss";
@import "./modern/blocks/sv-matrixdynamic.scss";
@import "./modern/blocks/sv-scroll.scss";
@import "./modern/blocks/sv-table.scss";
@import "./modern/blocks/sv-footer.scss";
@import "./modern/blocks/sv-btn.scss";
@import "./modern/blocks/sv-item.scss";
@import "./modern/blocks/sv-checkbox.scss";
@import "./modern/blocks/sv-radio.scss";
@import "./modern/blocks/sv-boolean.scss";
@import "./modern/blocks/sv-imagepicker.scss";
@import "./modern/blocks/sv-dropdown.scss";
@import "./modern/blocks/sv-tagbox.scss";
@import "./modern/blocks/sv-text.scss";
@import "./modern/blocks/sv-rating.scss";
@import "./modern/blocks/sv-comment.scss";
@import "./modern/blocks/sv-file.scss";
@import "./modern/blocks/sv-clearfix.scss";
@import "./modern/blocks/sv-completedpage.scss";
@import "./modern/blocks/sv-header.scss";
@import "./common-styles/sv-list.scss";
@import "./common-styles/sv-buttongroup.scss";
@import "./common-styles/sv-visuallyhidden.scss";
@import "./common-styles/sv-hidden.scss";

@import "./signaturepad.scss";

@import "./common-styles/window.scss";

@import "./boolean-checkbox.scss";
@import "./common-styles/sv-actionbar.scss";
@import "./common-styles/sv-titleactions.scss";
@import "./common-styles/sv-brand-info.scss";

//@import "./main.rtl.scss";
@import "./modern.m600.scss";

body {
  --sv-modern-mark: true;
}