html,
body,
#root {
  max-height: 100vh;
  overflow-y: hidden;
}

body {
  margin: 0;
}

#root {
  display: flex;

  .str-chat__channel-list {
    width: 30%;
  }

  .str-chat__channel {
    width: 100%;
  }

  .str-chat__thread {
    width: 45%;
  }
}
