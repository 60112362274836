.sv-question {
  overflow: auto;
  box-sizing: border-box;
  font-family: inherit;
}

.sv-question__title {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0.25em 0.44em;
  cursor: default;
  font-size: 1.25em;
  word-break: break-word;
}

.sv-question__required-text {
  line-height: 0.8em;
  font-size: 1.4em;
}

.sv-question__description {
  margin: 0;
  padding-left: 0.55em;
  font-size: 1em;
  word-break: break-word;
}

.sv-question__input {
  width: 100%;
  height: 1.81em;
}

.sv-question__content {
  margin-left: 0.55em;
}

.sv-question__erbox {
  color: $error-color;
  font-size: 0.74em;
  font-weight: bold;
}

.sv-question__erbox--location--top {
  margin-bottom: 0.4375em;
}

.sv-question__erbox--location--bottom {
  margin-top: 0.4375em;
}

.sv-question__footer {
  padding: 0.87em 0;
}

.sv-question__title--answer {
  background-color: $answer-background-color;
}

.sv-question__title--error {
  background-color: $error-background-color;
}

.sv-question__header--location--top {
  margin-bottom: 0.65em;
}

.sv-question__header--location--left {
  float: left;
  width: 27%;
  margin-right: 0.875em;
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .sv-question__header--location--left {
    float: right;
  }
}

.sv-question__header--location--bottom {
  margin-top: 0.8em;
}

.sv-question__content--left {
  overflow: hidden;
}

.sv-question__other {
  margin-top: 0.5em;
}

.sv-question__form-group {
  margin-top: 0.5em;
}

.sv-question--disabled {
  .sv-question__header {
    color: $disabled-text-color;
  }
}

.sv-image {
  display: inline-block;
}

.sv-question__title--expandable {
  cursor: pointer;
  display: flex;
  padding-right: 24px;
  align-items: center;
}

.sv-question__title--expandable {
  &:after {
    content: "";
    display: block;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px 12px;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
  }
}

.sv-question__title--expandable.sv-question__title--expanded {
  &:after {
    transform: rotate(180deg);
  }
}

.sv-question__icon {
  outline: none;
}

.sv-question__icon:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: 0.5em;
  width: 0.6em;
  margin-left: 1.5em;
  vertical-align: middle;
}

.sv-question__icon--expanded:before {
  transform: rotate(180deg);
}