body {
  margin: 0;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

$stream-chat-theme-version: '2';

@if $stream-chat-theme-version == '2' {
  #root {
    display: flex;
    height: 100%;
    

    .str-chat-channel-list {
      position: fixed;
      z-index: 1;
      margin-left: -30px;
      width: 0;

      &--open {
        width: 100%;
        margin-left: 0px;
        
      }
    }
    

    .str-chat-channel {
      width: 95%;
      
      
    }

    .str-chat__thread {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 1;
      
    }

    .str-chat__channel-header .str-chat__header-hamburger {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: none;
      background: DarkMagenta;

      svg {
        width: 25px;
        height: 25px;
        path {
          fill: var(--primary-color);
        }
      }
      

      
    }

    .rta str-chat__textarea str-chat__message-textarea-react-host{
      user-select: none;
    }

    @media screen and (min-width: 1200px) {
      //.str-chat-channel-list.thread-open {
      //  &.menu-open {
      //    width: 30%;
      //    height: 100%;
      //    position: fixed;
      //    z-index: 1;
      //  }
      //
      //  &.menu-close {
      //    width: 0;
      //  }
      //
      //  & + .channel .menu-button {
      //    display: block;
      //  }
      //}

      .str-chat-channel-list {
        width: 90%;
        max-width: 420px;
        position: initial;
        z-index: 0;
      }

      .str-chat__thread {
        position: initial;
        z-index: 0;
      }

      .str-chat__channel-header .str-chat__header-hamburger {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        background: transparent;
  
        svg {
          width: 25px;
          height: 25px;
        }
  
        &:hover {
          svg path {
            fill: var(--primary-color);
          }
        }
      }
    }

    @media screen and (min-width: 1400px) {
      //.str-chat-channel-list {
      //  max-width: 420px;
      //  position: initial;
      //  z-index: 0;
      //}

      .str-chat__thread {
        width: 45%;
        //position: initial;
        //z-index: 0;
      }

      .str-chat__channel-header .str-chat__header-hamburger {
        display: none;
      }
    }
  }
} @else {
  .menu-button {
    display: none;
  }
  .str-chat-channel-list {
    max-height: 100vh;
    overflow-y: auto;

    .str-chat__channel-list-messenger {
      flex: 1;
    }
  }

  .str-chat__thread {
    min-width: 100%;
    max-width: 100%;
  }

  @media only screen and (min-device-width: 768px) {
    .str-chat__thread {
      min-width: 35%;
      max-width: 35%;
    }
  }
}